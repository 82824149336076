
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  scroll-behavior: smooth;
}
.row{
  margin: 0;
  padding: 0;
}
::placeholder {
  color: #000;
  font-weight: 700;
}
::-webkit-scrollbar {display:none;}

.title-font{
  font-family: 'Lora', serif;
}

.a-link {
  color: white;
  text-decoration: none;
}
a{
  color: white !important;
  text-decoration: none;
}

.sub-title{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin: 42px 0px;
}

.sub-title-title{
  font-family: 'Lora', serif;
}

.sub-title-bar{
  border-top: 2px solid #000;
  flex: 1;
  margin-top: 16px;
}

.side-white-div{
  position: absolute;
  z-index:3;
  width: 300px;
  height: 300px;
  background: #FFFFFF;
  border-radius: 10px;
}
.card-img-top{
  width: 150px !important;
  height: 150px !important;
  border-radius: 50% !important;
  border: none !important;
}