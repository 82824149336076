.notFound{
    height: 500px;
    color: white;
    background: linear-gradient(180deg, #454545 0%, #161616 100%);
    padding-top: 160px;
}
.not-main-content{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* border: 1px solid blue; */
    gap: 32px;
}
.not-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    /* border: 1px solid red; */
}
.not-content >:nth-child(2){
    font-family: 'Courier New', Courier, monospace;
   
}
.img-not-found > img{
    width: 80px;
}

@media only screen and (max-width: 900px){
    .notFound{
        height: 450px;
    }
}

@media only screen and (max-width: 500px){
    .notFound{
        height: 400px;
    }
    .not-main-content{
        gap: 16px;
    }
    .img-not-found > img{
        width: 70px;
    }
}