.service-section{
    background:  #F1F1F1;
    background-image: url("../../assets/image/back-logo.png");
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
}
.service-content{
    display: flex;
    gap: 32px;
    
}

.service-content .stage {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
    flex: 1;
    font-weight: 700;
    max-height: 400px;
    height: auto;
    overflow: scroll;

    /* border: 1px solid rgb(60, 202, 16); */
}
.service-content .stage >span{
    padding: 8px 0px;
    width: 100%;
    text-align: center;
    background: #fff;
    border-radius: 10px;
    text-transform: capitalize;
    font-size: 16px;
    cursor: pointer;
}

.service-content .stage-img{
    flex: 1.5;
    display: flex;
    justify-content: center;

    /* border: 1px solid rebeccapurple; */
}
.service-content .stage-img > img{
    width: 100%;
    height: auto;
}
.service-content .stage-img {
    border: 10px solid #eb9a9a;
    border-radius: 10px;
}

.service-content .stage-content{
    flex: 1;
    text-align: justify;

    display: flex;
    flex-direction: column;
    gap: 24px;

    /* border: 1px solid rgb(0, 4, 255); */

}

/* set responsive */
@media only screen and (max-width: 992px){
    .service-content{
        display: flex;
        flex-direction: column;
        /* border: 1px solid red; */
        gap: 32px;
        
    }

    .service-content .stage{
        padding: 24px 24px;
        max-height: 350px;
        height: auto;
        margin: 0 24px;
        border-radius: 10px;
        /* -webkit-box-shadow: 1px 3px 4px 2px rgba(0,0,0,0.59); 
        box-shadow: 1px 3px 4px 2px rgba(0,0,0,0.59); */
    }
    .service-content .stage > span{
        font-size: 16px ;
        font-weight: 600;
    }
    .service-content .stage-content {
        display: none;
    }
    .service-content .stage-img{
        display: none;
    }
    
    
}

@media only screen and (max-width: 500px){
    .service-section{
        background-image: url("../../assets/image/back-logo-mobile.png");
    }
}


