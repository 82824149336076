.feature-new{
    max-width: 11440px;
   margin-left: auto;
   margin-right: auto;
    height: 700px;

    /* border: 1px solid red; */
}

.feature-new {
    background: url('../../assets//image/feature-background-img.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.feature-new .col-md-6{
    /* border: 1px solid blueviolet; */
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.feature-new .img-div{
    position: relative;
    width: 90%;
    height: 400px;
}
.feature-new .img-bg{
    position: absolute;
    width: 80%;
    height: 110%;
    background: #FFF;
    z-index: 3;
    top: -25%;
    left: -10%;
    border-radius: 10px;

    padding-left: 10%;
    padding-top: 5%;
}
.feature-new .img-div-img{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 5;
}
.feature-new .img-div-img > img{
    width: 450px;
    height: 300px;
    border-radius: 10px;
}
.feature-new .div-content{
    width: 90%;
    text-align: justify;
    /* border: 1px solid red;     */

    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
    border-radius: 10px;
    color: #fff;
    padding: 12px;
    font-size: 16px;

    overflow: hidden;
    
}

.kep-gap{
    height: 100px;
    width: 100%;
    /* border: 1px solid red; */
}

.feture-main-name{
    width: 30%;
    height: 50px;
    color: white;
    background: #000000;
    opacity: 0.8;
    border-radius: 0px 10px 10px 0px;
    z-index: 6;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* set responsive */
@media only screen and (max-width: 992px){
       
    .feature-new .img-div-img > img{
        width: 330px;
        height: 270px;
    }
    .feature-new{
        height: 950px;
    }
    .feture-main-name{
        width: 50%;
    }
    .feature-new .div-content{
        font-size: 14px;
    }
}

@media only screen and (max-width: 500px){
   
.feature-new .img-div-img > img{
    width: 300px;
    height: 230px;
}

.feture-main-name{
    width: 60%;
}
.feature-new .img-div{
    left: 5%;
}
.feature-new .img-div{
    height: 330px;
}
.feature-new{
    height: 900px;
}
.kep-gap{
    height: 60px;
}
}



