.whats-app-icon{
    position: fixed;
    right: 5%;
    top: 85vh;
    z-index: 60;
}
.whats-app-icon img{
    cursor: pointer;
    width: 70px;
}

/* for mobile view */
@media only screen and (max-width: 500px){
    .whats-app-icon  img{
        width: 65px;
    }
    
}
