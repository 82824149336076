
.technician-section .tech-card{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 16px;
    gap: 16px;
    
}

.technician-section{
    background-image: url("../../assets/image/back-logo.png");
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
}
@media only screen and (max-width: 500px){   
    .technician-section{
        background-image: url("../../assets/image/back-logo-mobile.png");
    }
}