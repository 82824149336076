.client-section{
    background-image: url("../../assets/image/back-logo.png");
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
}


.client-card .card-body{
    background-color: #D9D9D9;;
    border-radius: 0px 0px 10px 10px;
}
.client-card .card{
    border-radius: 10px;
    background: transparent;
} 


.client-card{
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 28px;
}
.client-card .client-name{
    padding: 5px;
    text-align: center;
    font-weight: 700;
}
.client-card .card-body{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    font-size: 12px;
    text-align: center;
}

.client-card .card{
    border: none;
}

.client-card-img{
    width: 100px;
    margin: auto;
    margin-bottom: 5px;
}

@media only screen and (min-width: 992px){
    .client-card .card-margin{
        margin-bottom: 80px;
    }
}
@media only screen and (max-width: 500px){   
    .client-section{
        background-image: url("../../assets/image/back-logo-mobile.png");
    }
}

