.pro-ser{
    background:  #F1F1F1;
    background-image: url("../../assets/image/back-logo.png");
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
}
.pro-ser .pro-ser-main{
    position: relative;
    height: 1300px;
}

.pro-ser-accordion{
    position: absolute;
    background: #fff;
    max-width: 800px;
    height: 700px;
    width: auto;
    padding: 24px;
    border-radius: 10px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;

    left: 24px;
    top: -140px;
}
.pro-ser-accordion >span{
    font-weight: 670;
    font-size: 28px;
}
/* ******************** */
.pro-ser-accordion .accordion{
    border: none !important;
    width: 90%;
    margin: auto;

    overflow: scroll;
    max-height: 700px;
    height: auto;
}

.pro-ser-accordion .accordionItemButton{
    display: flex;
    justify-content: space-between;
    border-color: transparent;
    border-top: 2px solid #000000;
    padding-top: 12px;
}

.accordion >:nth-child(1) .accordionItemButton{
    border-top-color: transparent;
    /* background: #b1e6a4; */
}

.pro-ser-accordion .accordionItemButton >span{
    font-weight: 600;
    font-size: 20px;
}
.pro-ser-accordion .accordionItemPanel{
    padding: 18px;
    padding-top: 0px;
    text-align: justify;
}
.pro-ser-accordion .accordionItemPanel > span{
    line-height: 18px;
}
.pro-ser .twenty-four{
    position: absolute;
    font-size: 28px;
    font-weight: 700;
    top: 600px;
    left: 48px;
} 
.pro-ser .twenty-four-content{
    position: absolute;
    width: 380px;
    height: 280px;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(10px);
    border-radius: 10px;
    font-size: 13px;
    text-align: justify;
    padding: 24px;
    color: #fff;

    z-index: 7;
    bottom: 290px;
    left: 400px;
}
.pro-ser .twenty-four-img{
    position: absolute;
    width: 600px;
    z-index: 5;
    bottom: 220px;
    left: 68px;
}

/* ***form** */
.pro-ser .contact-form{
    position: absolute;
    width: 400px;
    height: 600px;
    background-color: #fff;
    border-radius: 10px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;

    right: 48px;
    bottom: 48px;
}

.pro-ser .contact-form input,
.pro-ser .contact-form textarea{
    width: 350px;
    border: 2px solid #000;
    border-radius: 5px;
    padding: 5px 0;
    padding-left: 5px;
    outline: none;
}
.pro-ser .contact-form .btn{
    width: 350px !important;
    padding: 4px 0 !important;
}
.pro-ser .contact-form .cont-label{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 350px;
    font-size: 22px;
    font-weight: 700;
}
.pro-ser .contact-form .cont-input{
    display: flex;
    flex-direction: column;
    color: red;
}
.pro-ser .contact-form .cont-input>:nth-child(2){
    padding-left: 5px;
}


/* set responsive */
/* set responsive */
@media only screen and (max-width: 1400px){
    .pro-ser .twenty-four-img{
        width: 400px;
        z-index: 5;
        bottom: 320px;
        left: 48px;
    }
    .pro-ser .twenty-four-content{
        bottom: 161px;
        left: 10%
    }
}

@media only screen and (max-width: 992px){
    .pro-ser .pro-ser-main{
        height: 1700px;
    }
    .pro-ser-accordion >span{
        font-weight: 670;
        font-size: 24px;
    }
    .pro-ser-accordion .accordionItemButton >span{
        font-size: 20px;
    }
    .pro-ser-accordion{
        top: -200px;
        left: 10%;
    }
    .pro-ser .twenty-four{
        font-size: 24px;
        font-weight: 700;
        top: 530px;
        left: 20%;
    } 
    .pro-ser .twenty-four-img{
        width: 380px;
        z-index: 5;
        top: 620px;
        left: 10%;
    }
    .pro-ser .twenty-four-content{
        bottom: 680px;
        width: 360px;
    }

    @media only screen and (max-width: 500px){
        .pro-ser{
            background-image: url("../../assets/image/back-logo-mobile.png");
        }
        .pro-ser .pro-ser-main{
            height: 1600px;
        }
        .pro-ser-accordion >span{
            font-weight: 670;
            font-size: 22px;
        }
        .pro-ser-accordion .accordionItemButton >span{
            font-size: 16px;
        }
        .pro-ser-accordion.accordionItemPanel> span{
            font-size: 14px;
        }
        .pro-ser-accordion .accordionItemPanel{
            padding: 5px;
        }
        .pro-ser-accordion{
            left: 0%;
        }
        .pro-ser .twenty-four{
            font-size: 20px;
            left: 5%;
        } 
        .pro-ser .twenty-four-img{
            width: 320px;
            left: 0%;
        }
        .pro-ser .twenty-four-content{
            bottom: 620px;
            width: 300px;
            height: auto;
            font-size: 12px;
            left: 5%;
        }

        /* contact form */
        .pro-ser .contact-form{
            width: 330px;
            height: 550px;
            gap: 18px;
            right: 0%;
        }
        .pro-ser .contact-form input,
        .pro-ser .contact-form textarea{
            width: 300px;
            font-size: 14px;
        }
        .pro-ser .contact-form .btn{
            width: 300px !important;
        }
        .pro-ser .contact-form .cont-label{
            width: 300px;
            font-size: 18px;
        }
        
    }
}