.hero{
    background: linear-gradient(180deg, #454545 0%, #161616 100%);
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
}
.hero-section{
    position: relative;
    width: 100%;
    height: 480px;   
}

.hero-section .main-title{
    position: absolute;
    left:10%;
    top: 30%;
}
.hero-section .main-title > span{
    font-size: 38px;
    color: white;
    font-weight: 700;
}

.hero-section .main-des{
    position: absolute;
    bottom: 20%;
    right: 10%;
    color: white;
    width: 50%;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    text-align: justify;
}

.hero-section .main-des>:nth-child(2){
    font-size: 12px;
}

.hero-section .social-icon{
    display: flex;
    flex-direction: column;
    justify-content: center;align-items: center;
    gap: 12px;
    position: absolute;
    right: 0%;
    bottom: 40%;
    /* border: 1px solid red; */

    
}
.hero-section .social-icon div {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    align-items: center;
    cursor: pointer;
    /* border: 1px solid blue; */
}
.hero-section .social-icon > div >:nth-child(2){
    color: #fff;
    font-size: 20px;
}
.hero-section .social-icon > div >:nth-child(1){
    color: transparent;
    transition: all 300ms ease-in;
    font-size: 18px;
}
.hero-section .social-icon div:hover >:nth-child(1){
    color: white;

}



/* set responsive */
@media only screen and (max-width: 992px){
    .hero-section .main-title > span{
        font-size: 32px;
    }
    .hero-section .main-des{
        width: 80%;
        gap: 12px;
        /* border: 1px solid red; */
        height: 165px;
        overflow: hidden;
    }
}

@media only screen and (max-width: 500px){
    .hero-section .social-icon > div >:nth-child(1){
        font-size: 14px;
    }
}