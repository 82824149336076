.slider-div{
  position: relative;
  /* border: 1px solid rebeccapurple; */
  height: 800px;
  max-width: 1440px;
  margin: auto;
  color: white;
  background: linear-gradient(180deg, #454545 0%, #161616 100%);

}

.slider-div-img{
   position: absolute;
   overflow: hidden;
   width: 100%;
   height: 100%;
}
.slider-div-img >img{
    max-width: 100%;
    height: 100%;
}

.slider-div-content{
    /* border: 1px solid red; */
    padding: 48px;
    position: absolute;
    top: 25%;
    right: 60px;
    width: 600px;

    display: flex;
    flex-direction: column;
    align-items: flex-end;
    /* border: 1px solid red; */
    z-index: 10;
}
.slider-div-content button{
    width: 60%;
}
.slider-div-content >p{
    text-align: justify;
}

.slider-div-content >span{
   font-size: 28px;
   font-weight: 700;
}

.slider-click-arrow{
    position: absolute;
    width: 100%;
    z-index: 10;

    display: flex;
    justify-content: space-between;
    font-size: 90px;
    padding: 0 48px;
    top: 40%;

    /* border: 1px solid blue; */
}
.slider-click-arrow button{
    background-color: transparent;
    border: none;
    color: aliceblue;

    transition: all 300ms ease-in;
    /* border: 1px solid red; */
}
.slider-click-arrow button:hover{
    color: #dc3545;
    transform: scale(1.2);
}


/* set response */
@media only screen and (max-width: 992px){
    .slider-div{
        height: 600px;
    }
    .slider-div-content{
        top: 20%;
    }
}

@media only screen and (max-width: 768px){
    .slider-div{
        height: 550px;
    }
    .slider-div-content{
        width: 500px;
    }
}

@media only screen and (max-width: 600px){
    .slider-div{
        height: 450px;
    }
    .slider-div-content{
        width: 350px;
        height: auto;
        padding: 24px;
        top: 30%;
        right: 12px;
    }
    .slider-div-content >span{
        font-size: 22px;
    }
    .slider-div-content >p{
       font-size: 14px;
    }
    .slider-div-content>button{
        position: absolute;
        bottom: -10px;
    }
    .slider-click-arrow{
        font-size: 60px;
        padding: 0 8px;
        top: 45%;
    }
    .slider-click-arrow button{
        color: transparent;
    }
    .slider-click-arrow :hover > button{
        color: #dc3545;
        transform: scale(1.2);
    }
}
@media only screen and (max-width: 400px){
    .slider-div-content{
        width: 300px;
        top: 25%;
    }
}