.contact-main{
    background: #F1F1F1;
    background-image: url("../../assets/image/back-logo.png");
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;

}
.content-main-content{
    position: relative;
    width: 100%;
    height: 900px;
}
.content-main-content .contact-img{
    position: absolute;
    width: 512px;
    height: 308px;
    right: 75px;
    top: -8px;
    z-index: 5;
} 

.content-main-content .side-white-1{
    right: 41px;
    top: -42px;
}
.content-main-content .side-white-2{
    right: 322px;
    top: 69px;
}
.content-main-content .contact-info{
    position: absolute;
    bottom: 44px;
    margin: auto;
    width: 100%;
    background: #FFF;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 24px;
    padding: 24px;
    border-radius: 10px;
    text-align: center;
}
.content-main-content .contact-info>:nth-child(1){
    font-size: 28px;
    font-weight: 700;
}
.content-main-content .contact-info>:nth-child(2){
   padding: 0 50px;
}
.content-main-content .contact-info .c-info{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: start;
    padding:  12px 48px;
    font-size: 18px;
    /* border: 1px solid red; */
}
.content-main-content .contact-info .c-info>:nth-child(1){
    font-weight: 700;
}
.content-main-content .contact-form{
    position: absolute;
    top: -160px;
    width: 400px;
    height: 600px;
    background-color: #fff;
    border-radius: 10px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
}
.content-main-content .contact-form input,
.content-main-content .contact-form textarea{
    width: 350px;
    border: 2px solid #000;
    border-radius: 5px;
    padding: 5px 0;
    padding-left: 5px;
    outline: none;
}
.content-main-content .contact-form .btn{
    width: 350px !important;
    padding: 4px 0 !important;
}
.content-main-content .contact-form .cont-label{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 350px;
    font-size: 22px;
    font-weight: 700;
}
.content-main-content .contact-form .cont-input{
    display: flex;
    flex-direction: column;
    color: red;
}


.contact-form .input-error{
    border-color: red !important;
}

/* set responsive */
@media only screen and (max-width: 1200px){
    .content-main-content .contact-img{
        width: 412px;
        right: 75px;
        top: -8px;
        z-index: 5;
    } 
    .content-main-content .side-white-2{
        right: 222px;
        top: 18px;
    }
}

@media only screen and (max-width: 992px){
    .content-main-content{
        height: 1250px;
    }
    .content-main-content .contact-img{
        width: 390px;
        right: 10%;
        top: 450px;
        z-index: 5;
    } 
    .content-main-content .side-white-1,
    .content-main-content .side-white-2{
        display: none;
    }
    .content-main-content .contact-form{
        left: 10%;
    }
}

@media only screen and (max-width: 768px){
    .content-main-content{
        height: 1400px;
    }
}
@media only screen and (max-width: 500px){
    .contact-main{
        background-image: url("../../assets/image/back-logo-mobile.png");
    }
    .content-main-content{
        height: 1300px;
    }
    .content-main-content .contact-info{
        padding: 0px;
        /* border: 1px solid red; */
    }
    .content-main-content .contact-info>:nth-child(1){
        font-size: 22px;
        font-weight: 700;
    }
    .content-main-content .contact-info>:nth-child(2){
       padding: 0 24px;
       font-size: 15px;
    }
    .content-main-content .contact-info .c-info{
        font-size: 16px;
        /* border: 1px solid red; */
    }
    .content-main-content .contact-form{
        width: 340px;
        gap: 16px;
        left: 0;
    
    }
    .content-main-content .contact-form input,
    .content-main-content .contact-form textarea{
    width: 300px;
    }
    .content-main-content .contact-form .cont-label{
        width: 300px;
        font-size: 22px;
    }
    .content-main-content .contact-form .btn{
        width: 300px !important;
        padding: 4px 0 !important;
    }

    .content-main-content .contact-img{
        width: 300px;
        right: 5%;
        top: 455px;
        
    } 
}