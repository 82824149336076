.home-section{
    background:  #F1F1F1;
    background-image: url("../../assets/image/back-logo.png");
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
}
.home-section .card-parent{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
    /* border: 1px solid blue; */
}

.home-section .service-card{
    width: 90%;
    margin: auto;
    min-height: 240px;
    height: 100%;
    background: #fff;
    border-radius: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px;
    /* border: 1px solid red; */
    overflow: hidden;
}
.home-section .service-card .img-div{
    flex: 1;
    /* border: 1px solid blue; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
}
.home-section .service-card .content-div{
    flex: 2;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 8px;
    text-align: justify;
    padding: 24px;
    /* border: 1px solid red; */
}
.home-section .service-card .content-div>:nth-child(1){
    font-weight: 700;
    font-size: 22px;
}
.home-section .service-card .content-div>:nth-child(2){
    line-height: 16px;
    font-size: 12px;
}


/* set responsive */
@media only screen and (max-width: 500px){
    .home-section{
        background-image: url("../../assets/image/back-logo-mobile.png");
    }
     .home-section .service-card .content-div>:nth-child(1){
        font-size: 20px;
    }
    
}