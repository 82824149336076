.nav-section{
    position: relative;
    top: 50px;
    z-index: 20;
    display: flex;
    justify-content: center;

    /* border: 1px solid red; */
}

.navbarr{
    position: absolute;
    width: 100%;
   

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;

    background: #000;
    color: white;
    height: 60px;

    /* margin: 0 25px; */
    border-radius: 25px;

    font-weight: bold;
}

.navbarr .logo{
    border-radius: 15px;
}

.nav-value{
    border-bottom:2px solid #000;
    cursor: pointer;
    transition: all 300ms ease-in;
}

.nav-value:hover{
    border-bottom-color: white;
}
.navbarr .nav-value .nav-link{
    text-decoration: none;
    color: #fff;
}

.navbarr-mobile{
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    margin: auto;
    height: 40px;
    border-radius: 24px;
    background: #000;
}
.navbarr-mobile .logo{
    border-radius: 15px;
}
.navbarr-mobile> div{
    flex: 1;
    /* border: 1px solid red; */
}
.navbarr-mobile .menu-icon{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 18px;

}
.navbarr-mobile .menu-icon >div{
    /* border: 1px solid rgb(105, 24, 24); */
    cursor: pointer;
}


/* Set responsive */
@media only screen and (max-width: 992px){
    .navbarr{
        gap: 24px;
    }
}

@media only screen and (max-width: 768px){
    .navbarr{
        flex-direction: column;
        height: 280px;
        width: 80%;
        background: rgba(0, 0, 0, 0.8);
        -webkit-backdrop-filter: blur(10px);
        
       top: 80px;
    }
    .navbarr .logo{
        display: none;
    }
    .nav-value{
        border: none;
    }
}

@media only screen and (min-width: 768.111px){
   .navbarr-mobile{
    display: none;
   } 
}