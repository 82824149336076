.about{
    background: #F1F1F1;
    background-image: url("../../assets/image/back-logo.png");
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
}
.about-main{
    position: relative;
    max-width: 1440px;
    margin-left: auto;
    margin-right:auto ;
    height: 900px;
}
.info-div{
    position: absolute;
    width: 350px;
    height: 450px;
    padding: 24px;
    text-align: justify;
    z-index: 5;
    background: #FFFFFF;
    color: #000;
    border-radius: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 24px;
}
.info-div>:nth-child(1){
    text-align: center;
    font-size: 32px;
    font-weight: 700;
}
.info-div>:nth-child(2){
    font-size: 13px;
   
}

.mobile-div{
    left: 61px;
    top: -80px;
}
.available-div{
    right: 62px;
    bottom: 0;
}

.about-main .image-div-1{
    position: absolute;
    height: 350px;
    width: 600px;
    border-radius: 10px 10px 10px 0px;
    z-index: 5;
    top: 50px;
    right: 140px;
}
.about-main .image-div-2{
    position: absolute;
    height: 350px;
    width: 600px;
    border-radius: 10px 0px 10px 10px;
    z-index: 5;
    bottom: 114px;
    left: 140px;
}

.white-div-1{
    right: 90px;
    top: 10px;
}
.white-div-2{
    right: 550px;
    top: 40%;
}
.white-div-3{
    bottom: 24px;
    left: 88px;
}

/* ************form******************* */
.about-feedback{
    background: #F1F1F1;
    background-image: url("../../assets/image/back-logo.png");
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 450px;
}

.about-feedback .feedback-form{
    width: 100%;
    padding: 60px 48px;
    margin: auto;
    border-radius: 10px;
    color: #000;
    background: #fff;
    text-align: center;
    margin: 48px 0px;


}
.about-feedback .feedback-form >span{
    font-size: 38px;
    font-weight: 700;
}
.about-feed {
    margin-bottom: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.about-feed >small{
    color: red ;
    padding-left: 2px;
}
.about-feed > input, .about-feed > textarea{
    width: 80%;
    outline: none;
    border: 2px solid #000;
    border-radius: 6px;
    padding: 8px 24px;
}
.about-feed > input[type="file"]{
    border: none;
    color: #000;
    font-weight: 700;
    margin-left: -24px;
}
.about-feed > label{
    color: #002BC2;
    font-weight: 700;
}
.about-feed-btn{
    /* border: 1px solid red; */
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 80%;
    margin: auto;
}
.about-feed-btn .btn{
    width: 40%;
    padding: 2px;
    margin-top: 24px;
}

.input-error{
    border-color: red !important;
}


/* set responsive */
@media only screen and (max-width: 1200px){   
    .info-div{
        width: 340px;
        height: 420px;
        padding: 18px;
    } 
    .info-div>:nth-child(1){
        font-size: 28px;
    }
    .about-main .image-div-1,
    .about-main .image-div-2{
        height: 300px;
        width: 520px;
    }
    .about-main .image-div-2{
        bottom: 216px;
        left: 140px;
    }
    .white-div-3{
        bottom: 115px;
        left: 88px;
    }
}

@media only screen and (max-width: 1076px){
    .about-main .image-div-1,
    .about-main .image-div-2{
        height: 250px;
        width: 420px;
    }
    .white-div-2{
       display: none;
    }
}
@media only screen and (max-width: 978px){
    .white-div-1, .white-div-3{
        display: none;
     }
     .about-main{
        height: 1400px;
     } 
     .about-main .image-div-1{
        top: 365px;
        right: 10%;
    }
    .mobile-div{
        left:  10%;
    }
    .available-div{
        left:  10%;
        bottom: 300px;
    }
    .about-main .image-div-2{
        bottom: 0px;
        left: 10%;
        
    }
    /* ***  form *** */
    .about-feedback .feedback-form >span{
        font-size: 32px;
    }

    .about-feed-btn{
        /* border: 1px solid red; */
        width: 100%;
    }
    .about-feed-btn .btn{
        width: 60%;
    }
    .about-feed >input,
     .about-feed >textarea{
        width: 100%;
    }
}

@media only screen and (max-width: 768px){
    .about-feedback{
        height: 550px;
    }
}
@media only screen and (max-width: 500px){
    .about-main .image-div-1,
    .about-main .image-div-2{
        height: 200px;
        width: 360px;
    }
    .about-main .image-div-1{
        top: 350px;
        right: 10%;
    }
    .available-div{
        bottom: 215px;
    }
    
    .about-main{
        height: 1230px;
    }
    /* ***  form *** */
    .about-feedback .feedback-form >span{
        font-size: 28px;
    }
}   

@media only screen and (max-width: 500px){
    .about{
        background-image: url("../../assets/image/back-logo-mobile.png");
    }
    .about-feedback{
        height: 460px;
    }
}

    @media only screen and (max-width: 442px){
        .info-div{
            width: 300px;
            height: 420px;
            padding: 18px;
        } 
        .available-div{
            height: 440px;
        }
        .about-main .image-div-1,
        .about-main .image-div-2{
            height: 180px;
            width: 300px;
        }
        .info-div>:nth-child(1){
            font-size: 22px;
        }
        
        /* ***  form *** */
    .about-feedback .feedback-form >span{
        font-size: 22px;
    }
    .about-feed > input, .about-feed > textarea{
        padding: 4px 4px;
        font-size: 14px;
    }
    .about-feed > input[type="file"]{
       font-size: 14px;
        margin-left: 0px;
    }
    .about-feed-btn .btn{
        width: 100%;
    }
    

    }
