.footer-section{
    background: #000;
    padding: 50px 0px;
}
.footerr{
    display: flex;
    justify-content: space-between;
    color: white;

}

.footerr .sec-1,.footerr .sec-2{
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    /* border: 1px solid red; */
    /* line-height: 32px; */
} 

.footer-section .sec-4{
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 25px;

    margin-top: 50px;
}

.footer-section .sec-3{
    width: 100%;
    border-top: 2px solid white !important;
    margin: 50px 0px;
}
.footer-section .sec-2 .social-icons{
    /* border: 1px solid blue; */
    display: flex;
    justify-content: start;
    margin-top: 24px;
    gap: 32px;
    font-size: 24px;
}

.footer-section .sec-2 .social-icons>:nth-child(1),
.footer-section .sec-2 .social-icons>:nth-child(2),
.footer-section .sec-2 .social-icons>:nth-child(3),
.footer-section .sec-2 .social-icons>:nth-child(4){
    cursor: pointer;
}

.footer-section .sec-1>:nth-child(1),
.footer-section .sec-1>:nth-child(3),
.footer-section .sec-2>:nth-child(1){
    font-size: 18px;
    font-weight: 700;
}

/* set responsive */
@media only screen and (max-width: 768px){
    .footerr{
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 24px;
    }
    .footerr .sec-2{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .footer-section .sec-4{
        gap: 16px;
        font-size: 12px;
    }
    .footer-section .sec-3{
        margin: 30px 0px;
    }
    .footer-section .sec-2 .social-icons{
        gap: 48px;
    }
}